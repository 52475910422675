import React, { Component } from 'react'
import slack from "../images/Slack.svg"

class Callout extends Component {
    render() {
        return (
            <div className="callout">
                <img src={slack} alt="Slack Logo for the Soundbite Community"></img>
                <p>{this.props.data}</p>
            </div>
        )
    }
}


export default Callout;
