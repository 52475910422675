import React from 'react';
import ReactStoreBadges from 'react-store-badges';

const StoreBadges = () => {
    return (
        <div style={{ display: 'inline-flex',  marginBottom: '40px'  }}>
            <div style={{ marginRight: '16px' }}>
                <ReactStoreBadges
                    platform={'ios'}
                    target="_blank"
                    url={'https://apps.apple.com/us/app/soundbite-podcasts/id1507610176'} />
            </div>
            <div >
            <ReactStoreBadges
                platform={'android'}
                target="_blank"
                url={'https://play.google.com/store/apps/details?id=studio.darngood.soundbite'} />
            </div>
        </div>
    );
}


export default StoreBadges;


